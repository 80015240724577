<template>
  <div class="not-found">
    <div id="Content">
      <div class="shadow"></div>
      <div class="content-inner">
        <div class="heading">
          <h1>Oops! It seems you've taken a wrong turn but don't worry, we will show you the way to Go Pro! <a href="/">Click Here</a></h1>
          <h2>404 Error - Page Not Found</h2>
        
        </div>
        <!-- <div class="buttons">
          <diyobo-button
            type="primary bold"
            txt="Events in Your Area"
            @click="handleButtonClick(1)"
          />
          <diyobo-button
            type="secondary bold"
            txt="Sell Tickets"
            @click="handleButtonClick(2)"
          />
          <diyobo-button
            type="tertiary bold"
            txt="Learn More"
            @click="handleButtonClick(3)"
          />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import DiyoboButton from "../components/DiyoboButton.vue";

export default {
  name: "notfound",
  components: {
    DiyoboButton,
  },
  head() {
    return {
      title: "Page Not Found",
      meta: [{ name: "robots", content: "noindex" }],
    };
  },
  computed: {
    site() {
      return this.$store.state.site;
    },
  },
  methods: {
    handleButtonClick(index) {
      switch (index) {
        case 1:
          if (this.site === "buyers") {
            this.$router.push("/events");
          } else {
            switch (process.env.VUE_APP_ENVIRONMENT) {
              case "development":
                window.location.href = "https://localhost:3100/events";
                break;
              case "staging":
                window.location.href = "https://staging.incredevent.com/events";
                break;
              default:
                window.location.href = "https://incredevent.com/events";
                break;
            }
          }
          break;
        case 2:
          if (this.site === "planners") {
            this.$router.push("/dashboard");
          } else {
            switch (process.env.VUE_APP_ENVIRONMENT) {
              case "development":
                window.location.href = "https://localhost:3200";
                break;
              case "staging":
                window.location.href =
                  "https://planners.staging.incredevent.com";
                break;
              default:
                window.location.href = "https://planners.incredevent.com";
                break;
            }
          }
          break;
        case 3:
          window.location.href = "https://www.help.incredevent.com";
          break;
      }
    },
  },
};
</script>

<style lang="less" scoped>
.not-found {
  #Content {
    display: flex;
    flex: 1;
    background-image: url("../images/404_bg_gray.jpeg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: cover;
    height: 60vh;

    .content-inner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      backdrop-filter: blur(4px);
      padding: 20px;
      max-width: 550px;
      border: 1px solid #33373f;
      border-radius: 10px;
      background-color: rgba(0, 0, 0, 0.1);
      background-image: linear-gradient(
        180deg,
        transparent 30%,
        rgba(0, 0, 0, 0.25)
      );

      .heading {
        text-align: center;

        h1 {
          margin: 0;
          font-size: 20px;
          color: white;
        }

        h2 {
          font-size: 14px;
          font-weight: bold;
          color: #b1b1b1;
        }
      }

      .buttons {
        display: flex;
        justify-content: center;

        button {
          margin: 0 8px;
        }
      }
    }

    @media screen and (max-width: 730px) {
      height: calc(100vh - 100px);
    }
  }
}
</style>
